import Vue from 'vue';

import { Permissions } from '../permissions/';
import router, { ignoreNavigationDuplicatedErrorHandler } from '../router';
import services from '../services';
import store from '../store';
import { UPDATE_AUTH, UPDATE_USER, USER_LOGOUT } from '../store/modules/coreModule';

/**
 * The endpoint for logging in. This endpoint should be proxied by Webpack dev server
 * and maybe nginx in production (cleaner calls and avoids CORS issues).
 */
const LOGIN_URL = '/token';

/**
 * The endpoint for refreshing an access_token. This endpoint should be proxied
 * by Webpack dev server and maybe nginx in production (cleaner calls and avoids CORS issues).
 */
const REFRESH_TOKEN_URL = '/token';

/**
 * The endpoint for refreshing an access_token. This endpoint should be proxied
 * by Webpack dev server and maybe nginx in production (cleaner calls and avoids CORS issues).
 */
const LOGOUT_URL = '/api/piivo/account/logout';

/**
 * The endpoint for getting user informations.
 */
const USER_INFOS_URL = '/api/piivo/account/currentUserInfo';

/**
 * External signin base url
 */
const EXTERNAL_SIGNIN_URL_BASE = '/externalsignin';

/**
 * The options to pass into a Vue-resource http call. Includes
 * the headers used for login and token refresh and emulateJSON flag since we are hitting an
 * OAuth server directly that can't handle application/json.
 */
const AUTH_BASIC_HEADERS = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  emulateJSON: true,
};

// Displayed message on permissions checking after authentication
const UNAUTHORIZED_MESSAGE = 'Accès non autorisé';

/**
 * Authorization denied message
 */
const AUTHORIZATION_DENIED = 'authorization has been denied';

/**
 * Auth Plugin
 *
 * (see https://vuejs.org/v2/guide/plugins.html for more info on Vue.js plugins)
 *
 * Handles login and token authentication using OAuth2.
 */
export default {
  /**
   * Install the Authentication class.
   *
   * Creates a Vue-resource http interceptor to handle automatically adding auth headers
   * and refreshing tokens. Then attaches this object to the global Vue (as Vue.auth).
   *
   * @param {Object} Vue The global Vue.
   * @param {Object} options Any options we want to have in our plugin.
   * @return {void}
   */
  install(Vue, options) {
    Vue.http.interceptors.push((request, next) => {
      // Update request headers (Bearer, XSRF etc.)
      this.setHeaders(request);

      next((response) => {
        if (this._isInvalidToken(response)) {
          // Use window location instead of vue router because vue router is async :
          // the vue router might not match the actual url in the address bar yet
          const redirect = window.location.hash.substring(1);
          return this.logout(redirect);
          // Return this._refreshToken(request)
        }
      });
    });

    services.addService('auth', this);
  },

  /**
   * Login
   *
   * @param {Object} creds The username and password for logging in.
   * @param {string} creds.username The username and password for logging in.
   * @param {string} creds.password The username and password for logging in.
   * @param {string|null} [redirect=null] Redirect path on login success, or
   * redirect for login page if login error
   * @returns {Promise<{success:boolean; errorMessage:string|null}>} result
   */
  async login(creds, redirect = null) {
    // Build login params
    const params = { grant_type: 'password', username: creds.username, password: creds.password };

    try {
      // Login then load user informations and redirect
      const response = await Vue.http.post(LOGIN_URL, params, AUTH_BASIC_HEADERS);

      // Store token with token response
      this._storeToken(response);

      // Load user informations with extended properties already received
      const res = await this.loadUserInfos(redirect);
      if (!res.success) {
        return res;
      }

      await router.push({ path: redirect });

      return { success: true };
    } catch (err) {
      return {
        success: false,
        errorMessage: err.body.error_description ? err.body.error_description : err.statusText,
      };
    }
  },

  /**
   * Gets user informations store them and redirect user.
   * @param {string|false|null} [redirect=null] Optional redirect path for the login page, if logged out.
   * False if should not go to login page at all
   * @returns {Promise<{success:boolean; errorMessage:string|null}>} result
   */
  async loadUserInfos(redirect = null) {
    try {
      const userInfosResponse = await Vue.http.get(USER_INFOS_URL);

      if (
        userInfosResponse == null ||
        userInfosResponse.body == null ||
        (userInfosResponse.body.message &&
          userInfosResponse.body.message.toLowerCase().includes(AUTHORIZATION_DENIED))
      ) {
        // No user informations
        this.logout(redirect);
        return { success: false, errorMessage: null };
      }

      this._storeUser(userInfosResponse);
      if (!this.hasPermission(Permissions.CALL_API)) {
        // No required permissions
        this.logout(redirect);
        return { success: false, errorMessage: UNAUTHORIZED_MESSAGE };
      }

      return { success: true };
    } catch (err) {
      // Error => No user informations
      return { success: false, errorMessage: null };
    }
  },

  /**
   * Logout
   *
   * Call the logout api
   * Clear all data in our Vuex store (which resets logged-in status)
   * and navigate to login page.
   *
   * @param {string|false|null} [redirect=null] Optional redirect path for the login page.
   * False if should not go to login page at all
   * @return {void}
   */
  logout(redirect = null) {
    // Logout, don't await
    Vue.http.post(LOGOUT_URL);

    // Cleaning and redirection
    store.dispatch(USER_LOGOUT);
    if (redirect !== false) {
      router
        .push({ name: 'login', query: redirect ? { redirect } : null })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch(ignoreNavigationDuplicatedErrorHandler);
    }
  },

  /**
   * Update headers on a Vue-resource request (Authorization, XSRF, etc.).
   * @param {Request} request The Vue-Resource request instance to set headers on.
   * @return {void}
   */
  setHeaders(request) {
    // Add Authorization header if access token is present (OAuth).
    const token = store.state.auth.accessToken;
    const hasAuthHeader = request.headers.has('Authorization');
    if (token && !hasAuthHeader) {
      request.headers.set('Authorization', 'Bearer ' + store.state.auth.accessToken);
    }

    if (request.method.toLowerCase() !== 'get') {
      // Update XSRF token header
      const xsrfToken = Vue.cookie.get('XSRF-TOKEN');
      if (xsrfToken) {
        request.headers.set('X-XSRF-TOKEN', xsrfToken);
      }
    }
  },

  /**
   * Get authentication types and their enabled property.
   * @returns {Array} with all authentication types enabled
   */
  async getExternalAuthenticationTypes() {
    const response = await Vue.http.get(`${EXTERNAL_SIGNIN_URL_BASE}/providers`);
    return response.body;
  },

  /**
   * Get signin url for external authentication.
   * @param {String} provider - The provider name
   * @param {String} redirect - The return url after authentication
   * @returns {String} the external signin url.
   */
  getExternalSigninUrl(provider, redirect) {
    return `${EXTERNAL_SIGNIN_URL_BASE}?provider=${provider}&returnUrl=${redirect}`;
  },

  /**
   * Check if the user has the permission passed in parameter.
   *
   * @param {String} permissionName - permission to check.
   * @return {Boolean} True if user has permission, false otherwise.
   */
  hasPermission(permissionName) {
    return (
      permissionName === '' ||
      permissionName == null ||
      (store.state.user.permissions != null &&
        store.state.user.permissions.includes(permissionName))
    );
  },

  /**
   * Retry the original request.
   *
   * Let's retry the user's original target request that had recieved a invalid token response
   * (which we fixed with a token refresh).
   *
   * @param {Request} request The Vue-resource Request instance to use to repeat an http call.
   * @return {Promise}
   */
  // eslint-disable-next-line @typescript-eslint/require-await
  async _retry(request) {
    this.setHeaders(request);

    return Vue.http(request).catch((err) => err);
  },

  /**
   * Refresh the access token
   *
   * Make an ajax call to the OAuth2 server to refresh the access token (using our refresh token).
   *
   * @private
   * @param {Request} request Vue-resource Request instance, the original request that we'll retry.
   * @return {Promise}
   */
  async _refreshToken(request) {
    const params = { grant_type: 'refresh_token', refresh_token: store.state.auth.refreshToken };

    try {
      const result = await Vue.http.post(REFRESH_TOKEN_URL, params, AUTH_BASIC_HEADERS);

      this._storeToken(result);
      return this._retry(request);
    } catch (errorResponse) {
      if (this._isInvalidToken(errorResponse)) {
        this.logout();
      }
      return errorResponse;
    }
  },

  /**
   * Store tokens
   *
   * Update the Vuex store with the access/refresh tokens received from the response from
   * the Oauth2 server.
   *
   * @private
   * @param {Response} response Vue-resource Response instance from an OAuth2 server.
   *      that contains our tokens.
   * @return {void}
   */
  _storeToken(response) {
    const auth = store.state.auth;
    auth.isLoggedIn = true;
    auth.accessToken = response.body.access_token;
    auth.refreshToken = response.body.refresh_token;
    auth.issued = response.body['.issued'];
    auth.expires = response.body['.expires'];
    store.commit(UPDATE_AUTH, auth);
  },

  /**
   * Store user informations
   *
   * Update the Vuex store with the user informations.
   *
   * @private
   * @param {Response} response Vue-resource Response instance from api with user informations.
   * @return {void}
   */
  _storeUser(response) {
    // Update user informations from response
    const user = store.state.user;
    user.userName = response.body.userName;
    user.displayName = response.body.displayName;
    user.language = response.body.language || 'fr_FR';
    user.permissions = response.body.permissions;

    // Set user extended properties
    const extendedProperties = response.body.extendedProperties;
    if (extendedProperties) {
      // Convert departments string into string array
      if (extendedProperties.departments) {
        extendedProperties.departments = extendedProperties.departments.split(',');
      }

      user.extendedProperties = extendedProperties;
    }

    // Update token (for external authentication and Oauth) and authenticate user (flag isLoggedIn)
    const auth = store.state.auth;
    auth.accessToken = response.body.accessToken;
    auth.isLoggedIn = true;
    store.commit(UPDATE_AUTH, auth);
    store.commit(UPDATE_USER, user);
  },

  /**
   * Check if the Vue-resource Response is an invalid token response.
   *
   * @private
   * @param {Response} response The Vue-resource Response instance received from an http call.
   * @return {boolean}
   */
  _isInvalidToken(response) {
    const status = response.status;
    const error = response.data ? response.data.error : null;

    return (
      status === 403 ||
      status === 401 ||
      error === 'invalid_grant' ||
      error === 'invalid_token' ||
      error === 'expired_token'
    );
  },
};
